@import '../index.scss';

#bm {
  .bm-TastemakersCampus__header {
    display: block;
    text-align: left;

    h1 {
      font-size: 4.5rem;
    }
  }

  .bm-TastemakersCampus__headerContainer {
    max-width: 50%;
  }

  .bm-TastemakersCampus__fullScreenSection {
    position: relative;

    h1 {
      font-size: 5.5rem;
    }
  }

  img.bm-TastemakersCampus__fullScreenImage {
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    object-fit: cover;
    height: 100vh;
    width: 100vw;
  }

  .bm-TastemakersCampus__fullScreenOverlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.3);
  }

  .bm-TastemakersCampus__benefitsHeader {
    h1 {
      font-size: 5.5rem;
    }
  }

  @include media-breakpoint-down(md) {
    .bm-TastemakersCampus__header {
      display: flex;
      justify-content: center;

      h1 {
        font-size: 3.5rem;
      }

      h1, p {
        text-align: center !important;
      }
    }

    .bm-TastemakersCampus__headerContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 100%;
    }

    .bm-TastemakersCampus__benefitsHeader {
      h1 {
        font-size: 2rem;
      }
    }

    .bm-TastemakersCampus__benefitsImage {
      width: 100% !important;
    }

    .bm-TastemakersCampus__benefitsDescription {
      width: 100% !important;
    }

    .bm-TastemakersCampus__fullScreenSection {
      h1 {
        font-size: 3rem;
      }
    }
  }
}