@import '../index.scss';

#bm {
  .bm-Answer {
    width: 100%; // TODO: make single column on mobile
    margin-bottom: 2rem;
    margin-right: 2%;
    background-color: #fff;
    border: 0.5px solid #e2e2e2;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    min-height: 150px;
  }

  @include media-breakpoint-down(md) {
    .bm-Answer {
      width: 100%;
      margin-right: 0;
    }
  }

  .bm-Answer--blank {
    border: 2.5px dashed #e2e2e2;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: rgba(0,0,0,0.05);
  }

  .bm-Answer__promptTitle {
    margin-bottom: 16px;
  }

  .bm-Answer__actionButton {
    position: absolute;
    bottom: -5px;
    right: -5px;
    box-shadow: 1px 1px 5px rgba(75, 75, 75, 0.3);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    padding: 0 !important;
  }

  .bm-Answer__actionButton--primary {
    background-color: #96f;
    color: #fff;
  }
}