@import '../index.scss';

// #bm {
  .bm-Post__share_link {
    transition: ease 250ms;
    height: 50px !important;
    width: 50px !important;
    max-height: 50px !important;
    max-width: 50px !important;
    border-radius: 25px !important;
    color: #fff !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-decoration: none !important;
    background-color: #1d2124;
  } 

  a.bm-Post__share_link--facebook.bm-Post__share_link--color {
    background-color: #4267B2 !important;
  }

  a.bm-Post__share_link--twitter.bm-Post__share_link--color {
    background-color: #03A9F4 !important;
  }

  a.bm-Post__share_link--pinterest.bm-Post__share_link--color {
    background-color: #c8232c;
  }

  a.bm-Post__share_link--link.bm-Post__share_link--color {}

  .bm-Post__share_link:hover:not(.bm-Post__share_link--color) {
    color: #96f !important;
  }

  .bm-Post__share_link:focus:not(.bm-Post__share_link--color) {
    color: #96f !important;
  }

  .bm-Post__share_link.bm-Post__share_link--color:hover:not(.bm-Post__share_link--color) {
    color: #96f !important;
  }

  a.bm-Post__share_link.bm-Post__share_link--facebook.bm-Post__share_link--color:focus {
    background-color: lighten(#4267B2, 50%) !important;
  }

  a.bm-Post__share_link.bm-Post__share_link--twitter.bm-Post__share_link--color:focus {
    background-color: lighten(#03A9F4, 50%) !important;
  }

  a.bm-Post__share_link.bm-Post__share_link--link.bm-Post__share_link--color:focus {
    background-color: lighten(#383838, 50%) !important;
  }
// }