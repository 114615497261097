@import '../index.scss';

#bm {
  .bm-Slideshow__slide {
    opacity: 0;
    position: absolute;
    display: block !important;
    transition: ease-in 600ms;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .bm-Slideshow__slide--current {
    opacity: 1;
    transition: ease-out 600ms;
  }

  .bm-Slideshow__dot {
    height: 1rem !important;
    width: 1rem !important;
    border-radius: 50% !important;
    border: 3px solid #9966ff !important;
    background-color: transparent !important;
    transition: ease-in 200ms;
    max-height: 1000px !important;
    padding: 0 !important;
    z-index: 1000;
  }

  .bm-Slideshow__dot--current {
    background-color: #9966ff !important;
    transition: ease-out 500ms;
  }

  @include media-breakpoint-up(lg) {
    .bm-Slideshow__leftSide {
      margin-right: 3rem;
    }
  }

  @include media-breakpoint-up(md) {
    .bm-Slideshow__rightSide {
      display: flex;
    }
  }

  @include media-breakpoint-down(md) {
    .bm-Slideshow__rightSide {
      display: none !important;
    }
  }
}