@import '../index.scss';

#bm {
  .bm-ViewTaste {}

  .bm-ViewTaste:hover {
    background-color: darken($color: #fff, $amount: 5) !important;
  }

  .bm-ViewTaste_heading {
    font-size: 5.5rem;
  }

  @include media-breakpoint-down(md) {
    .bm-ViewTaste_heading {
      text-align: center;
      font-size: 3rem;
    }
  }

  .progress {
    background-color: transparent;
    border: 2px solid rgba(255,255,255, 0.8);
  }

  .progress-bar {
    background-color: rgba(255,255,255, 0.8);
  }

  .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(153, 102, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(153, 102, 255, 0.15) 50%, rgba(153, 102, 255, 0.15) 75%, transparent 75%, transparent);
  }
}

.bm-ViewTaste__bg--unauthenticated {
  margin-top: -75px;
  background-color: #271845 !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 80vh;
  width: 100vw;
}

.bm-ViewTaste__slide {
  opacity: 0;
  transition: ease-in 600ms;
}

.bm-ViewTaste__slide--current {
  opacity: 1;
  transition: ease-out 600ms;
}

.bm-ViewTaste__dot {
  height: 1rem !important;
  width: 1rem !important;
  border-radius: 50% !important;
  border: 3px solid #fff !important;
  background-color: transparent !important;
  transition: ease-in 200ms;
  max-height: 1000px !important;
  padding: 0 !important;
  margin: 0 !important;
  z-index: 100000;
}

.bm-ViewTaste__dot--current {
  background-color: #fff !important;
  transition: ease-out 500ms;
}

.bm-ViewTaste__leftSide {
  justify-content: center;
}

@include media-breakpoint-up(lg) {
  .bm-ViewTaste__leftSide {
    margin-right: 3rem;
    justify-content: space-between;
  }
}

@include media-breakpoint-up(md) {
  .bm-ViewTaste__rightSide {
    display: flex;
  }
}

@include media-breakpoint-down(md) {
  .bm-ViewTaste__rightSide {
    display: none !important;
  }
}

@include media-breakpoint-up(md) {
  .btn-block + .btn-block {
    margin-top: 0 !important;
  }
}

.bm-ViewTaste__bg {
  background: linear-gradient( rgba(153, 102, 255, 1), rgba(153, 102, 255, 1)), url('../assets/images/concert-background.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

@include media-breakpoint-up(lg) {
  .bm-ViewTaste_getting_started_button {
    width: 200px;
    margin-right: 1rem;
  }
}

@include media-breakpoint-up(xl) {
  .bm-ViewTaste_getting_started_button {
    width: 200px;
  }
}

