@import '../index.scss';

#bm {
  .bm-Fandom {
    .text-primary {
      color: #C7ACFF !important;
    }

    a {
      color: #C7ACFF !important;
    }

    .btn-primary {
      color: rgba(0, 0, 0, 0.8) !important;
      background-color: #C7ACFF;
      border-color: #C7ACFF;
    }

    .bm-Fandom__header_image {
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 80vh;
      width: 100vw;
    }

    .bm-Fandom__header_image--beyonce {
      background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../assets/images/beyonce-beyhive-dating-app.webp');
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .bm-Fandom__header_image--bts-army {
      background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../assets/images/bts-bts-army-dating-app.png');
    }

    .bm-Fandom__header_image--beliebers {
      background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../assets/images/justin-bieber-beliebers-dating-app.jpeg');
    }

    .bm-Fandom__text--outline {
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      font-weight: 500;
    }

    @include media-breakpoint-down(md) {
      h1 {
        font-size: 3rem !important;
      }

      h1, p {
        text-align: center !important;
      }

      .bm-Fandom_testimonialImages {
        text-align: center;
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 1rem;
      }

      .bm-Fandom_productHuntBadge {
        display: none;
      }
    }
  }
}