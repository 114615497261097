@import '../index.scss';

#bm {
  .bm-Home__sectionHalf {
    margin-bottom: 20px;
  }

  .bm-ViewTaste {}

  .bm-ViewTaste:hover {
    background-color: darken($color: #fff, $amount: 5) !important;
  }

  .bm-Home_heading {
    font-size: 5.5rem;
  }

  .bm-Home__card {}

  .bm-Home__why_image {
    background: linear-gradient( rgba(153, 102, 255, 0.94), rgba(153, 102, 255, 0.75), rgba(153, 102, 255, 0.94)), url('../assets/images/anderson-paak-concert.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1;
    min-height: 450px;
    min-width: 450px;
  }

  .bm-Home_pressText {
    margin-bottom: 1.5rem;
  }

  .bm-Home_pressLogo {
    opacity: 1;
  }

  .bm-Home_investors::-webkit-scrollbar {
    display: none;
  }

  .bm-Home_investors {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .bm-Home_investorLogo {
    opacity: 1;
  }

  .bm-Home__bannerImage.bm-Home__bannerImage--large svg {
    height: auto !important;
  }
  
  .bm-Home_bannerDescription--center-mobile {
    display: flex;
  }

  .carousel-control-next,
  .carousel-control-prev /*, .carousel-indicators */ {
      filter: invert(100%);
  }

  @include media-breakpoint-down(md) {
    .bm-Home__card {
      margin-bottom: 2rem;
    }

    .bm-Home__bannerImage {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 2rem;
      width: 100%;
    }

    .bm-Home__bannerImage.bm-Home__bannerImage--large {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 2rem;
    }

    .bm-Home__bannerDescription {
      text-align: center;
      justify-content: center;
      width: 100%;
    }

    .bm-Home_bannerDescription--center-mobile {
      justify-content: center;
    }
  }

  @include media-breakpoint-up(lg) {
    .bm-Home__card {
      flex: 1;
    }

    .bm-Home__bannerImage {
      flex: 2;
      margin-right: 4.5rem;
    }

    .bm-Home__bannerImage.bm-Home__bannerImage--large {
      flex: 2.5;
      margin-right: 4.5rem;
    }

    .bm-Home__bannerImage--reverse {
      margin-right: 0rem !important;
    }
  
    .bm-Home__bannerDescription {
      flex: 3;
    }
  }

  .progress {
    background-color: transparent;
    border: 2px solid rgba(255,255,255, 0.8);
  }

  .progress-bar {
    background-color: rgba(255,255,255, 0.8);
  }

  .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(153, 102, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(153, 102, 255, 0.15) 50%, rgba(153, 102, 255, 0.15) 75%, transparent 75%, transparent);
  }

  .bm-Music__header_image1 {
    margin-top: -75px;
    background: linear-gradient(45deg, rgba(153, 102, 255, 1), rgb(107, 71, 179));
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20vh;
  }

  .bm-Home__bg {
    margin-top: -75px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
    width: 100vw;
  }

  .bm-ViewTaste__slide {
    opacity: 0;
    transition: ease-in 600ms;
  }

  .bm-ViewTaste__slide--current {
    opacity: 1;
    transition: ease-out 600ms;
  }

  .bm-ViewTaste__dot {
    height: 1rem !important;
    width: 1rem !important;
    border-radius: 50% !important;
    border: 3px solid #fff !important;
    background-color: transparent !important;
    transition: ease-in 200ms;
    max-height: 1000px !important;
    padding: 0 !important;
    margin: 0 !important;
    z-index: 1000;
  }

  .bm-ViewTaste__dot--current {
    background-color: #fff !important;
    transition: ease-out 500ms;
  }

  .bm-Home_pressLogo {
    width: 15%;
  }

  .bm-Home_investorLogo {
    height: 9rem;
    width: auto;
    aspect-ratio: 1;

    svg {
      width: 100%;
      aspect-ratio: 1;
    }
  }

  .bm-ViewTaste__leftSide {
    height: 100%;
  }

  @include media-breakpoint-up(lg) {
    .bm-ViewTaste__leftSide {
      margin-right: 3rem;
      align-items: center;
    }
  }

  @include media-breakpoint-up(md) {
    .bm-ViewTaste__rightSide {
      display: flex;
    }
  }

  @include media-breakpoint-down(md) {
    .bm-Home_header {
      align-items: center;
      width: 100%;
    }

    .bm-ViewTaste__leftSide {
      // margin-top: 5rem;
      align-items: flex-start;
      height: 85%;
    }
    
    .bm-ViewTaste__rightSide {
      display: none !important;
    }
  }

  .bm-ViewTaste__bg {
    background: linear-gradient( rgba(153, 102, 255, 1), rgba(153, 102, 255, 1)), url('../assets/images/concert-background.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }

  @include media-breakpoint-down(md) {
    .bm-Home_heading {
      text-align: center;
      font-size: 3rem;
    }
  }

  @include media-breakpoint-down(md) {
    .bm-Home_subheading {
      text-align: center;
    }
  }

  @include media-breakpoint-up(lg) {
    .bm-ViewTaste_getting_started_button {
      width: 200px;
    }
  }

  @include media-breakpoint-up(xl) {
    .bm-ViewTaste_getting_started_button {
      width: 200px;
    }
  }

  @include media-breakpoint-down(md) {
    .bm-About_coverImage {
      position: relative;
      padding: 0;
      margin: 0;
    }

    .bm-Home_why_description {
      position: absolute;
      max-width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
      text-align: center;
      bottom: 0;
    }

    .bm-Home_pressText {
      margin-bottom: 0.5rem;
    }

    .bm-Home_pressLogo {
      width: 33%;
      margin-top: .75rem;
      margin-bottom: .75rem;
      // margin-left: 2rem;
      // margin-right: 2rem;
    }

    .bm-Home_investorLogo {
      height: 5rem;
      width: auto;
      aspect-ratio: 1;
      margin-top: .75rem;
      margin-bottom: .75rem;
      aspect-ratio: 1;
  
      svg {
        width: 100%;
        aspect-ratio: 1;
      }
      // margin-left: 2rem;
      // margin-right: 2rem;
    }
  }

  @include media-breakpoint-up(md) {
    .bm-About_coverImage {
      margin-bottom: 3rem;
    }
    
    .bm-About_summaryContainer {
      position: relative;
    }
  }
}