@import '../index.scss';

.bm-SubscribeButton_coverImage {
  width: 500px !important;
  height: 500px !important;
  background-size: cover !important;
  margin-left: -5px !important;
  margin: 0;
  padding: 0;
}

.bm-SubscribeButton_coverImage__closeButton {
  display: none !important;
}

@include media-breakpoint-down(md) {
  .bm-SubscribeButton_coverImage {
    margin-left: -3px !important;
    width: 115% !important;
    height: 250px !important;
  }

  .bm-SubscribeButton_coverImage__closeButton {
    width: 30px;
    height: 30px;
    border-radius: 22.5px;
    background-color: rgba(0,0,0,0.25);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}